import React, { useEffect, useState, useCallback } from "react";
import { Card, Table, Tag, Tooltip } from "antd";
import Axios from "../AxiosWrapper";
import { SettingOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { User, UserTag } from "../types";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
const { Column } = Table;

export const UserList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [userFilter, setUserFilter] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState(30);
  const [tagFilter, setTagFilter] = useState<string[]>([]);

  const debouncedFetchUsers = useCallback(
    debounce(() => {
      fetchUsers();
    }, 300),
    [page, limit, userFilter, tagFilter]
  );

  useEffect(() => {
    debouncedFetchUsers();
    return () => debouncedFetchUsers.cancel();
  }, [page, limit, userFilter, tagFilter]);

  const fetchUsers = async () => {
    try {
      const { data } = await Axios.get(
        "/api/admin/list_users"

        //   {
        //   limit,
        //   offset: (page - 1) * limit,
        //   search: userFilter,
        //   tagFilter,
        // }
      );
      // const { users, total } = data;
      data.forEach((user: User) => {
        user.key = user.id;
      });
      setUsers(data);
      // setTotalUsers(total);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  const getUsers = () => {
    // return users;
    if (!userFilter) {
      return users.filter((x) => containsAll(x.tags || [], tagFilter));
    }
    return users.filter(
      (user) =>
        user.email.includes(userFilter) ||
        user.referral_code?.includes(userFilter.toUpperCase())
    );
  };
  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserFilter(e.target.value);
  };
  const onChangeTagFilter = (tags: UserTag[]) => {
    setTagFilter(tags.map((x) => x.tag));
  };
  return (
    <Card>
      <div style={{ display: "flex" }}>
        <Input.Search onChange={onChangeSearch} style={{ width: 200 }} />
        <FilterByTag onChange={onChangeTagFilter} />
      </div>
      <br />
      <Table
        loading={isLoading}
        style={{
          maxWidth: 1600,
          height: 1000,
          overflow: "auto",
          minWidth: isMobileDevice() ? "inherit" : 1600,
        }}
        dataSource={getUsers()}
        pagination={{
          pageSize: limit,
          onChange: (page) => setPage(page),
          onShowSizeChange(current, size) {
            setLimit(size);
          },
          // total: totalUsers,
        }}
      >
        <Column title="Email Address" dataIndex="email" key="email" />
        {!isMobileDevice() && (
          <>
            <Column
              title="License expires in"
              dataIndex="license_expiry_date"
              key="license_expiry_date"
              render={(licenseExpiry: string, user: User) => {
                if (user.license_type === "credits") return;
                const daysRemaining = licenseExpiry
                  ? Math.round(
                      (new Date(licenseExpiry).getTime() - Date.now()) / 8.64e7
                    )
                  : 0;

                return (
                  <span
                    style={{
                      color:
                        daysRemaining <= 0
                          ? "#e02c2c"
                          : daysRemaining <= 3
                          ? "yellow"
                          : "inherit",
                    }}
                  >
                    {daysRemaining + " days"}
                  </span>
                );
              }}
              sorter={(a: User, b: User) =>
                new Date(a.license_expiry_date || 0).getTime() -
                new Date(b.license_expiry_date || 0).getTime()
              }
            />

            <Column
              title="last seen"
              key="last_seen"
              sorter={(a: User, b: User) =>
                new Date(getLastSeen(a)).getTime() -
                new Date(getLastSeen(b)).getTime()
              }
              sortDirections={["descend", "ascend"]}
              render={(user: User) => {
                const last_seen = getLastSeen(user);
                if (last_seen === 0) return null;
                const daysAgo = Math.abs(
                  Math.round(
                    (new Date(last_seen).getTime() - Date.now()) / 8.64e7
                  )
                );
                if (daysAgo === 0)
                  return (
                    "today at " +
                    new Date(last_seen).getHours() +
                    ":" +
                    new Date(last_seen).getMinutes()
                  );
                return daysAgo + " days ago";
              }}
            />

            <Column
              title="Games Played"
              dataIndex="games_played"
              key="games_played"
              sorter={(a: User, b: User) => a.games_played - b.games_played}
            />
            <Column
              title="License Type"
              dataIndex="license_type"
              key="license_type"
              sorter={(a: User, b: User) =>
                a.license_type.localeCompare(b.license_type)
              }
            />
            <Column
              title="Credits"
              dataIndex="credits"
              key="credits"
              sorter={(a: User, b: User) => a.credits - b.credits}
            />
            <Column title="Note" dataIndex="note" key="note" />
            <Column
              title="Referral Code"
              dataIndex="referral_code"
              key="referral_code"
            />
          </>
        )}

        <Column
          key="settings"
          render={(text: string, user: User) => (
            <Tooltip title="Settings">
              <Link to={`/users/${user.id}`}>
                <SettingOutlined style={{ fontSize: 20, cursor: "pointer" }} />
              </Link>
            </Tooltip>
          )}
        />
      </Table>
    </Card>
  );
};
function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

type FilterByTagProps = {
  onChange: (tags: UserTag[]) => void;
};
const FilterByTag = (props: FilterByTagProps) => {
  const [availableTags, setAvailableTags] = useState<UserTag[]>([]);
  const [selectedTags, setSelectedTags] = useState<UserTag[]>([]);

  useEffect(() => {
    const getAvailableTags = async () => {
      try {
        const res = await Axios.get(`/api/admin/user_tags`);
        setAvailableTags(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    getAvailableTags();
  }, []);
  useEffect(() => {
    props.onChange(selectedTags);
  }, [selectedTags]);
  const onSelectTag = (tag: UserTag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };
  if (!availableTags) return null;
  return (
    <div style={{ marginLeft: 10 }}>
      {availableTags.map((x) => (
        <Tag
          style={{
            opacity: selectedTags.includes(x) ? 1 : 0.5,
            cursor: "pointer",
          }}
          //bordered={selectedTags.includes(x)}
          onClick={() => onSelectTag(x)}
          key={x.tag}
          color={x.color}
        >
          {x.tag}
        </Tag>
      ))}
    </div>
  );
};
function containsAll(arr1: any[], arr2: any[]) {
  return arr2.every((element) => arr1.includes(element));
}

function getLastSeen(user: User) {
  const { last_game_started, last_game_finished } = user;
  if (!last_game_started && !last_game_finished) return 0;

  return Math.max(
    new Date(last_game_started || 0).getTime(),
    new Date(last_game_finished || 0).getTime()
  );
}
