import React, { Component } from "react";
import { Button, Modal, Form, Input, message, Select, InputNumber } from "antd";
import { Store } from "antd/lib/form/interface";
import { MailOutlined, LockOutlined, UserAddOutlined } from "@ant-design/icons";
import Axios from "../AxiosWrapper";
import { showError } from "../utils";
import { useAuth } from "../contexts/AuthContext";

export default class CreateUser extends Component {
  state = { showModal: false };
  onClose = () => {
    this.setState({ showModal: false });
  };
  render() {
    return (
      <div>
        <Modal
          footer={
            <Button key="back" onClick={this.onClose}>
              Cancel
            </Button>
          }
          okText={null}
          maskClosable={false}
          visible={this.state.showModal}
          onCancel={this.onClose}
        >
          <CreateUserForm onClose={this.onClose} />
        </Modal>
        <Button
          icon={<UserAddOutlined />}
          type="primary"
          onClick={() => this.setState({ showModal: true })}
        >
          Create User
        </Button>
      </div>
    );
  }
}

type FormProps = {
  onClose: Function;
};
const CreateUserForm = (props: FormProps) => {
  const { auth } = useAuth();
  const onFinish = async (values: Store) => {
    const usernameExists = (
      await Axios.get("/api/check_email", {
        email: values.email,
      })
    ).data;

    if (usernameExists) {
      return Modal.error({ title: "Email address already taken!" });
    }
    try {
      const res = await Axios.post("/api/admin/create_user", values);
      if (res.status === 401) {
        return message.error("Invalid password!");
      }
      if (res.status !== 200) {
        return message.error("Internal error");
      }

      message.success("Successfully added user!");
      props.onClose();
    } catch (error) {
      showError("Failed to create user", error);
    }
  };
  return (
    <Form onFinish={onFinish} className="login-form">
      <Form.Item
        hasFeedback
        name="email"
        rules={[
          { type: "email", message: "Invalid email address" },
          { required: true, message: "Please enter your email address!" },
        ]}
      >
        <Input
          prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please enter a valid password!",
          },
        ]}
        hasFeedback
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item
        name="confirm"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject("The two passwords don't match!");
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Confirm Password"
        />
      </Form.Item>
      <Form.Item initialValue="credits" label="License Type" name="licenseType">
        <Select>
          <Select.Option value="credits">Credits</Select.Option>
          {auth?.role !== "distributor" && (
            <Select.Option value="license">License</Select.Option>
          )}
        </Select>
      </Form.Item>

      {auth?.role !== "distributor" && (
        <Form.Item
          initialValue={100}
          label="Starting Credits"
          name="startingCredits"
        >
          <InputNumber min={0} max={10000} />
        </Form.Item>
      )}
      <Form.Item label="Referral code" name="referredByCode">
        <Input maxLength={8} placeholder={"Referral Code (Optional)"} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Finish
        </Button>
      </Form.Item>
    </Form>
  );
};
