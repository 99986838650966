import React, { createContext, useContext, useState, useEffect } from "react";
import { getToken, getTokenData, setToken } from "../utils";
import { message } from "antd";
import { Role } from "../types";

type Auth = {
  email: string;
  isValid: boolean;
  role: Role;
};

type AuthContextType = {
  auth: Auth | null;
  initAuth: () => Promise<void>;
  handleLogout: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [auth, setAuth] = useState<Auth | null>(null);

  const initAuth = async () => {
    const token = getToken();
    if (!token) return;
    try {
      const auth = getTokenData();
      if (!auth || !auth.isValid) {
        message.error("Login expired!");
        return handleLogout();
      }
      setAuth(auth as any);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogout = () => {
    setToken("");
    setAuth(null);
    window.location.reload();
  };

  useEffect(() => {
    initAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ auth, initAuth, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
